import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="row">
                    <div className="col col-2"></div>
                    <div className="col col-6">
                        <div className="footer-text text-note">
                            En High Voltage Engineering, somos especialistas en el desarrollo de ingeniería para SUBESTACIONES ELECTRICAS Y LINEAS DE TRANSMISION (AEREAS Y SUBTERRANEAS).
                            <br />
                            High Voltage Engineering S.A de C.V. tiene como cede la ciudad de Hermosillo, Sonora.
                        </div>
                    </div>
                    <div className="col col-2"></div>
                </div>
                <div className="row">
                    <div className="col col-10">
                        <a href="mailto:contacto@hveng.net" target="_blank" rel='noreferrer'>
                            <i className="fa fa-envelope"></i>
                        </a>
                        <a href="https://www.youtube.com/watch?v=NaGhNrr2p9o" target="_blank" rel='noreferrer'>
                            <i className="fab fa-youtube"></i>
                        </a>
                        {/* <a href="https://api.whatsapp.com/send?phone=" target="_blank" rel='noreferrer'>
                            <i className="fab fa-whatsapp"></i>
                        </a> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col col-10">
                        <div className="footer-text">
                            <p>hveng.net</p>
                            <p>Desarrollador por <a href="https://ramico.com.mx"><span className="ra">ra</span><span className="mi">mi</span><span className="co">co</span></a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer