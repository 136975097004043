import React from 'react';
import './HeroSection.css';

function HeroSection() {
    React.useEffect(() => {

    }, [])

    return (
        <div className='hero-container' style={{ backgroundImage: `url(${window.location.origin}/img/bg_home.jpg)` }}>
            {/* <video src='https://www.youtube.com/watch?v=NaGhNrr2p9o' autoPlay loop muted /> */}
            <img src='/img/logo_large.png' width="400" alt="HVE" title="HVE" />
            {/* <div className='heto-btns'>
                <Button to="https://residenciales.yodesarrollo.mx" className='btns' buttonStyle={"btn--outline"} buttonSize={'btn--large'}>
                    {data[2][1]}
                </Button>
                <Button to={"Modelo/Aria"} className='btns' buttonSize={'btn--large'}>
                    Modelo Aria
                </Button>
            </div> */}
        </div >
    )
}

export default HeroSection