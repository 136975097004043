import React, { useEffect, useState } from 'react';
import './ContentHome.css';
import { useParams } from 'react-router-dom';

function ContentHome() {
    const params = useParams();
    useEffect(() => {
    }, []);

    // const [index, setIndex] = useState(0);
    // const [showError, setShowError] = useState(false);
    // const [selectGallery, setSelectGallery] = useState();
    // const [displayModal, setDisplayModal] = useState(false);

    // const next = () => {
    //     setIndex((i) => (i + 1) % selectGallery.images.length);
    // };
    // const prev = () => {
    //     setIndex(
    //         (i) => (((i - 1) % selectGallery.images.length) + selectGallery.images.length) % selectGallery.images.length
    //     );
    // };
    // const onClickOutside = (e) => {
    //     if (e.target.localName !== "img" && e.target.localName !== "span") {
    //         setDisplayModal(false);
    //     }
    // };

    // if (showError)
    //     return (
    //         <div className='container'>
    //             <h1>La página no se encuentra disponible...</h1>
    //         </div>
    //     )
    // else
    return (
        <div className='container'>
            <div id="Empresa" className='row marginTop20'>
                <h1 className='primaryTitle'>Empresa </h1>
            </div>
            <div className='row'>
                <div className='col col-12'>
                    <div className='textBox'>
                        <p>
                            HVE es una empresa de servicios de ingeniería en el rubro eléctrico, especializado en el desarrollo
                            de estudios preliminares, ingeniería básica e ingeniería de detalle para el desarrollo de líneas de
                            potencia y subestaciones eléctricas desde 4.16 kv hasta 400 kv.
                            Fundada en Hermosillo, Sonora, compuesta por ingenieros y profesionales con más de 20 años
                            desarrollando proyectos en el sector con nuestra experiencia y entendimiento de las necesidades
                            del proyecto y las legislaciones vigentes, se brindan soluciones factibles y de valor coherentes con
                            las solicitudes particulares, consolidándonos como un soporte técnico confiable para nuestros
                            clientes.
                        </p>
                        <p>
                            La mayoría de nuestros servicios se han realizado en México y EUA, utilizando las especificaciones
                            de CFE, ASCE, IEEE, NEC, NESC, RUS, EPRI entre otras normativas solicitadas por la zona operadora
                            o nuestros clientes.
                        </p>
                    </div>
                </div>
            </div>
            <div id="Servicios" className='row marginTop20'>
                <h1 className='primaryTitle'>SERVICIOS</h1>
            </div>
            <div className='row marginTop20'>
                <h1 className='secondaryTitle'>ESTUDIOS TOPOGRÁFICOS</h1>
            </div>
            <div className='row'>
                <div className='col col-5'>
                    <div className='textBox'>
                        <p>
                            Levantamiento de la zona para conocer los desniveles de terreno con el uso de las mas nuevas tecnologías GNSS, drone rtk y fotografías para obtener dimensiones de los predios, elevaciones y perfiles, en el sistema utilizado en el proyecto.
                            <ul className='primary-list'>
                                <li><b>Fotogrametria con drones:</b>
                                    <ul className='sublist'>
                                        <li>Levantamiento de fotografias y video</li>
                                        <li>Planos topográficos</li>
                                        <li>Modelos de elevación</li>
                                        <li>Nubes de puntos</li>
                                        <li>Curvas de nivel</li>
                                        <li>Ortomosaicos</li>
                                        <li>Modelos 3d</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className='primary-list'>
                                <li><b>Topografía:</b>
                                    <ul className='sublist'>
                                        <li>Levantamiento de puntos de control</li>
                                        <li>Deslindes</li>
                                        <li>Liga a la rgna</li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className='col col-5'>
                    <img src={`${window.location.origin}/img/levantamiento-topografico.jpg`} width={"100%"} />
                </div>
            </div>
            <div className='row marginTop20'>
                <h1 className='secondaryTitle text-right'>ESTUDIOS AMBIENTALES</h1>
            </div>
            <div className='row'>
                <div className='col col-5'>
                    <img src={`${window.location.origin}/img/estudios-ambientales.jpg`} width={"100%"} />
                </div>
                <div className='col col-5'>
                    <div className='textBox'>
                        <p>
                            Colaboramos en sus proyectos para la obtención de permisos, autorizaciones y licencias necesarias en el cumplimiento ambiental.
                            <ul className='primary-list'>
                                <li>Cambio uso de Suelo</li>
                                <li>Manifestaciones de impacto ambiental.</li>
                                <li>Licencia ambiental integral</li>
                                <li>Muestreos de vegetación y fauna</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <div className='row marginTop20'>
                <h1 className='secondaryTitle'>ESTUDIOS ELÉCTRICOS</h1>
            </div>
            <div className='row'>
                <div className='col col-5'>
                    <div className='textBox'>
                        <p>
                            Modelado del sistemas eléctricos evaluados con los diferentes casos de carga y funcionamiento para determinar el adecuado funcionamiento de los equipos.
                            <ul className='primary-list'>
                                <li>Arc Flash</li>
                                <li>Flujos</li>
                                <li>Corto circuito</li>
                                <li>Armónicos</li>
                                <li>Coordinación de protecciones</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className='col col-5'>
                <img src={`${window.location.origin}/img/estudios.jpeg`} width={"100%"} />
                </div>
            </div>
            <div className='row marginTop20'>
                <h1 className='secondaryTitle text-right'>TRÁMITES ANTE EL CENACE</h1>
            </div>
            <div className='row'>
                <div className='col col-5'>
                    <img src={`${window.location.origin}/img/gestoria-graficos.png`} width={"100%"} />
                </div>
                <div className='col col-5'>
                    <div className='textBox'>
                        <p>
                            Apoyo en la elaboración de documentos necesarios para introducir la información al portal del CENACE.
                            <ul className='primary-list'>
                                <li>Documentos técnicos:
                                    <ul className='sublist'>
                                        <li>Llenado del Anexo IV</li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <div className='row marginTop20'>
                <h1 className='secondaryTitle'>INGENIERÍA SUBESTACIONES</h1>
            </div>
            <div className='row'>
                <div className='col col-5'>
                    <div className='textBox'>
                        <p>
                            Son las instalaciones necesarias para transformar los voltajes de transmisión o subtransmisión para suministrar la energía al sistema eléctrico de la planta o la instalación según sea solicitado.
                        </p>
                        <p>
                            Los Documentos, informes técnicos y planos necesarios electromecánicos y civiles para la ejecución del proyecto.
                            <h2>Ingeniería Civil</h2>
                            <ul className='primary-list'>
                                <li>Caminos de Acceso</li>
                                <li>Terracerías</li>
                                <li>Drenajes Pluviales</li>
                                <li>Diseño de estructuras mayores y menores</li>
                                <li>Diseño de cimentaciones</li>
                                <li>Entre otros.</li>
                            </ul>
                            <h2>Ingeniería Electromecánica</h2>
                            <ul className='primary-list'>
                                <li>Descripción del proyecto</li>
                                <li>Diagramas unifilares</li>
                                <li>Determinación de cargas mecánicas</li>
                                <li>Desarrollo de Trincheras y ductos</li>
                                <li>Cálculos de coordinación de aislamiento</li>
                                <li>Sistema contraincendios</li>
                                <li>Lista de cables de comunicaciones</li>
                                <li>Especificación de equipos.</li>
                                <li>Entre otros.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className='col col-5'>
                    <img src={`${window.location.origin}/img/subestacion.png`} width={"100%"} />
                </div>
            </div>
            <div className='row marginTop20'>
                <h1 className='secondaryTitle text-right'>INGENIERIA LÍNEAS ELÉCTRICAS</h1>
            </div>
            <div className='row'>
                <div className='col col-5'>
                    <img src={`${window.location.origin}/img/ingenieria-electrica.jpeg`} width={"100%"} />
                </div>
                <div className='col col-5'>
                    <div className='textBox'>
                        <p>
                            El uso de las líneas eléctricas es transportar la energía de la generación hacía las cargas (casas o industria) las cuales de manera general se subdividen de manera aérea y subterránea.
                        </p>
                        <p>
                            Las líneas aéreas utilizan cables de aluminio AAC, AAAC, ACSR entre otros con el uso de postes de concreto, madera, fibra de vidrio, FRP y Acero  y/ o sobre torres autosoportadas de acero.
                        </p>
                        <p>
                            Las líneas subterráneas utilizan cables de potencia de cobre/ aluminio forrados, los cuales se colocan en los bancos de ductos o directamente enterrados,
                        </p>
                        <p>
                            Los Documentos, informes técnicos y planos necesarios electromecánicos y civiles para la ejecución del proyecto.
                            <h2>Ingeniería Civil</h2>
                            <ul className='primary-list'>
                                <li>Memoria de cálculos estructurales de postes y torres.</li>
                                <li>Memoria de calculo de cimentaciones (zapatas, Pilas y plotes)</li>
                                <li>Calculo de registros de potencia.</li>
                                <li>Entre otros.</li>
                            </ul>
                            <h2>Ingeniería Electromecánica</h2>
                            <ul className='primary-list'>
                                <li>Descripción del proyecto</li>
                                <li>Selección de trayectoria</li>
                                <li>Calculos de de coordinación de aislamiento</li>
                                <li>Determinación de uso de cables</li>
                                <li>Cálculo de tensiones de jalado</li>
                                <li>Entre otros.</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentHome
