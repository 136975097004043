import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection/HeroSection';
import ContentHome from './ContentHome';


export default function Home() {
    return (
        <>
            <HeroSection />
            <ContentHome />
        </>
    )
}
