import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button/Button';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }
    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);


    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                        <img src='/img/navbar_logo.png' alt="YoDesarrollo" title="yoDesarrollo" width="200" />
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                Inicio
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <a href='#Empresa' className='nav-links' onClick={closeMobileMenu}>
                                Empresa
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href='#Servicios' className='nav-links' onClick={closeMobileMenu}>
                                Servicios
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href="mailto:contacto@hveng.net" target="_blank" rel='noreferrer' className='nav-links' onClick={closeMobileMenu}>
                                <i className="fa fa-envelope"></i>
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href="https://www.youtube.com/watch?v=NaGhNrr2p9o" target="_blank" rel='noreferrer' className='nav-links' onClick={closeMobileMenu}>
                                <i className="fab fa-youtube"></i>
                            </a>
                        </li>
                        <li className='nav-item' style={{ background: "#2199b2" }}>
                            <a href='http://www.tuukuul.net/Login' target={"_blank"} className='nav-links' onClick={closeMobileMenu}>
                                Acceso a tu proyecto
                            </a>
                        </li>
                        {/* <li className='nav-item'>
                            <Link to='/modelos' className='nav-links' onClick={closeMobileMenu}>
                                Modelos
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/proyectos' className='nav-links' onClick={closeMobileMenu}>
                                Proyectos
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/signup' className='nav-links-mobile' onClick={closeMobileMenu}>
                                Registrarse
                            </Link>
                        </li> */}
                    </ul>
                    {/* {button && <Button buttonStyle={'btn--outline'}>Registrarse</Button>} */}
                </div>
            </nav>
        </>
    )
}

export default Navbar